import React, { useState } from 'react';
import { Card, InputNumber } from 'antd';
import TwoColumnRow from './TwoColumnRow';
import PgVgSlider from './PgVgSlider';
import './InputBox.less';
import './Grid.less';

function InputBox(props) {

  const [targetWeight, setTargetWeight] = props.targetWeight;
  const [targetNic, setTargetNic] = props.targetNic;
  const [targetVg, setTargetVg] = props.targetVg;
  const [targetFlavor, setTargetFlavor] = props.targetFlavor;

  const [sourceNic, setSourceNic] = props.sourceNic;
  const [sourceVg, setSourceVg] = props.sourceVg;

  const cardTabList = [
    {
      key: 'target',
      tab: 'target',
    },
    {
      key: 'source',
      tab: 'source',
    },
  ];

  const [key, setKey] = useState(cardTabList[0].key); // For UI

  let targetContent = (
    <>
      <TwoColumnRow label="batch weight:">
        <InputNumber
          defaultValue={targetWeight}
          value={targetWeight}
          autoFocus={true}
          min={20}
          step={20}
          formatter={value => `${value} ml`}
          parser={value => value.replace(/\D/g, '')}
          onChange={(value) => setTargetWeight(value)}
          className="weight-input"
        />
      </TwoColumnRow>

      <TwoColumnRow label="nicotine strength:">
        <InputNumber
          defaultValue={targetNic}
          value={targetNic}
          min={0.1}
          step={0.1}
          formatter={value => `${value} mg/ml`}
          parser={value => value.replace(/[^\d.]/g, '')}
          onChange={value => setTargetNic(value)}
        />
      </TwoColumnRow>

      <PgVgSlider
        vg={[targetVg, setTargetVg]}
      />

      <TwoColumnRow label="flavoring:">
        <InputNumber
          defaultValue={targetFlavor}
          value={targetFlavor}
          min={0}
          max={25}
          step={1}
          formatter={value => `${value}%`}
          parser={value => value.replace(/\D/g, '')}
          onChange={(value) => setTargetFlavor(value)}
          className="flavoring-input"
        />
      </TwoColumnRow>
    </>
  );

  const sourceContent = (
    <>
      <TwoColumnRow label="nicotine strength:">
        <InputNumber
          defaultValue={sourceNic}
          value={sourceNic}
          min={10}
          step={1}
          formatter={value => `${value} mg/ml`}
          parser={value => value.replace(/[^\d.]/g, '')}
          onChange={value => setSourceNic(value)}
        />
      </TwoColumnRow>

      <PgVgSlider
        vg={[sourceVg, setSourceVg]}
      />
    </>
  );

  const cardContentList = {
    target: targetContent,
    source: sourceContent,
  };

  return (
    <div className="InputBox">
      <Card
        style={{ width: '100%' }}
        title="goal and mixing materials"
        tabList={cardTabList}
        activeTabKey={key}
        onTabChange={key => {
          setKey(key);
        }}
      >
        {cardContentList[key]}
      </Card>
    </div>
  );
}

export default InputBox;