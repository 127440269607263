import React from 'react';
import { Layout, Typography } from 'antd';
import Calculator from './Calculator';
import './App.less';

function App() {

  const { Header, Content, Footer } = Layout;
  const { Text } = Typography;

  return (
    <div className="App">
      <Layout>
        <Header>
          <h1>DIY E-Juice Recipe Calculator</h1>
        </Header>
        <Content>
          <Calculator />
        </Content>
        <Footer>
          <Text>
            Powered by <a href="https://ant.design/" target="_blank" rel="noopener noreferrer">Ant Design</a>
            <br />
            Created by Josh Cornett
          </Text>
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
