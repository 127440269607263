import React, { useState, useEffect } from 'react';
import InputBox from './InputBox';
import OutputBox from './OutputBox';
import './Calculator.less';

function Calculator() {

  const [targetWeight, setTargetWeight] = useState(500);
  const [targetNic, setTargetNic] = useState(3);
  const [targetVg, setTargetVg] = useState(60);
  const [targetFlavor, setTargetFlavor] = useState(15);

  const [sourceNic, setSourceNic] = useState(100);
  const [sourceVg, setSourceVg] = useState(0);

  const [finalVgMl, setFinalVgMl] = useState();
  const [finalPgMl, setFinalPgMl] = useState();
  const [finalNicMl, setFinalNicMl] = useState();
  const [finalFlavorMl, setFinalFlavorMl] = useState();

  const calculateRecipe = (tWeight, tNic, tVg, tFlavor, sNic, sVg) => {
    const nicMl = (tWeight * tNic) / sNic;
    const tPg = 100 - tVg;
    const sPg = 100 - sVg;
    //const nicVg = (sVg / 100) * nicMl; // not needed
    const nicPg = (sPg / 100) * nicMl;
    const flavorMl = (tFlavor / 100) * tWeight;
    const pgMl = ((tPg / 100) * tWeight) - (nicPg + ((tFlavor / 100) * tWeight));
    const vgMl = tWeight - (nicMl + pgMl + flavorMl);

    setFinalVgMl(vgMl);
    setFinalPgMl(pgMl);
    setFinalNicMl(nicMl);
    setFinalFlavorMl(flavorMl);
  }

  useEffect(() => {
      calculateRecipe(
        targetWeight,
        targetNic,
        targetVg,
        targetFlavor,
        sourceNic,
        sourceVg
      )
    },
    [targetWeight, targetNic, targetVg, targetFlavor, sourceNic, sourceVg]
  )

  return (
    <div className="Calculator">
      <InputBox
        targetWeight={[targetWeight, setTargetWeight]}
        targetNic={[targetNic, setTargetNic]}
        targetVg={[targetVg, setTargetVg]}
        targetFlavor={[targetFlavor, setTargetFlavor]}
        sourceNic={[sourceNic, setSourceNic]}
        sourceVg={[sourceVg, setSourceVg]}
      />
      <OutputBox
        finalVgMl={finalVgMl}
        finalPgMl={finalPgMl}
        finalNicMl={finalNicMl}
        finalFlavorMl={finalFlavorMl}
      />
    </div>
  );
}

export default Calculator;