import React from 'react';
import { Card, Alert } from 'antd';
import TwoColumnRow from './TwoColumnRow';
import './OutputBox.less';
import './Grid.less';

function OutputBox(props) {

  const finalVgMl = props.finalVgMl;
  const finalPgMl = props.finalPgMl;
  const finalNicMl = props.finalNicMl;
  const finalFlavorMl = props.finalFlavorMl;

  let mixAlert = null;
  if (finalVgMl < 0 || finalPgMl < 0) {
    mixAlert = (
      <Alert
        className="mix-alert"
        message="Not Mixable!"
        description="Try adjusting target values."
        type="error"
        showIcon
      />
    );
  }

  const outputContent = (
    <>
      {mixAlert}

      <TwoColumnRow label="VG:">
        <p>{`${+finalVgMl?.toFixed(2)} ml`}</p>
      </TwoColumnRow>

      <TwoColumnRow label="PG:">
        <p>{`${+finalPgMl?.toFixed(2)} ml`}</p>
      </TwoColumnRow>

      <TwoColumnRow label="Nicotine:">
        <p>{`${+finalNicMl?.toFixed(2)} ml`}</p>
      </TwoColumnRow>

      <TwoColumnRow label="Flavoring:">
        <p>{`${+finalFlavorMl?.toFixed(2)} ml`}</p>
      </TwoColumnRow>
    </>
  );

  return (
    <div className="OutputBox">
      <Card
        style={{ width: '100%' }}
        title="final recipe"
      >
        {outputContent}
      </Card>
    </div>
  );
}

export default OutputBox;