import React from 'react';
import { Row, Col } from 'antd';
import './Grid.less';

function TwoColumnRow(props) {
  return (
    <Row>
      <Col span={12}>
        <label>{props.label}</label>
      </Col>
      <Col span={12}>
        {props.children}
      </Col>
    </Row>
  );
}

export default TwoColumnRow;