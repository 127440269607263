import React from 'react';
import { Row, Col, Slider } from 'antd';
import './Grid.less';

function PgVgSlider(props) {

  const [vg, setVg] = props.vg;

  return (
    <Row>
      <Col span={5}>
        <label className="label-pg">
          {`PG: ${100 - vg}%`}
        </label>
      </Col>
      <Col span={14}>
        <Slider
          defaultValue={vg}
          value={vg}
          step={5}
          onChange={value => setVg(value)}
        />
      </Col>
      <Col span={5}>
        <label className="label-vg">
          {`VG: ${vg}%`}
        </label>
      </Col>
    </Row>
  );
}

export default PgVgSlider;